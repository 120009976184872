<template>
  <!-- 登录页面 -->
  <div class="full_box full_height">
    <canvas id="canvas" class="canvas"
      >{{ t('627') }}</canvas
    >
    <section class="center_box clearfix">
      <div class="center_half center_left fl">
        <img
          class="earth"
          src="./../../asset/img/login/earth.png"
          alt="earth"
        />
        <span class="hide_name">{{ company }}</span>
        <div class="logo_div">
          <img :src="logo" alt="" v-show="logo" />
          <span>{{ t(company) }}</span>
        </div>
      </div>
      <div class="center_half center_right fl">
        <div class="form_box">
          <h4>Hello!</h4>
          <h5>
            <span>{{ t('628') }}</span>
            <span class="theme_word color_word">{{ t('629') }}</span>
            <span>{{ appName }}</span>
          </h5>
          <el-form
            ref="elFormRef"
            label-position="top"
            :model="elForm"
            :rules="formRules"
            class="el_form"
          >
            <el-form-item :label="t('630')" class="form_item" prop="user">
              <el-input v-model="elForm.user" @blur="checkExist" />
              <span class="iconfont icon-chenggong" v-show="userExist"></span>
            </el-form-item>
            <el-form-item :label="t('631')" class="form_item" prop="password">
              <el-input
                v-model="elForm.password"
                :type="visiblePass ? 'input' : 'password'"
                @keyup.enter="handleLogin"
              />
              <span
                class="iconfont icon-yincangbukejian hover_opacity"
                v-show="!visiblePass"
                @click="visiblePass = !visiblePass"
              ></span>
              <span
                class="iconfont icon-xianshikejian hover_opacity"
                v-show="visiblePass"
                @click="visiblePass = !visiblePass"
              ></span>
            </el-form-item>
          </el-form>
          <div class="check_radio_box">
            <el-checkbox v-model="isRemember" :label="t('632')" />
          </div>
          <el-button class="blue_btn" @click="handleLogin">{{ t('629') }}</el-button>
          <div>
            {{ t('633') }}
            <span class="theme_word color_word hover_opacity">{{ t('634') }}</span>
          </div>
        </div>
        <div class="version_name">{{ t('635') }}:V0.1.62</div>
      </div>
    </section>
  </div>
</template>

<script>
import { t } from '../../languages';
import {
  nextTick,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  toRefs,
  computed,
} from "vue-demi";
import user from "@/network/user";
import CryptoJS from "crypto-js";
import { ElMessage } from "element-plus";
import { router } from "@/router";
import { Ball } from "@/utils/canvas-ball.js";
import JSEncrypt from "jsencrypt";
import { store } from "@/store";
import system from "@/network/system";
// import socket from "@/utils/websocket";

export default {
  name: "login",
  setup() {
    // const { openSocketConnect } = socket();
    const appName = t(window.g.appName);
    document.title = appName

    //同步logo
    const state = reactive({
      elForm: {
        user: "",
        password: "",
      },
      formRules: {
        user: [{ required: true, message: t("636"), trigger: "blur" }],
        password: [{ required: true, message: t("637"), trigger: "blur" }],
      },
      logo: "",
      defaultLogo: '',
      company: window.g.company,
    });
    let canvas = null;
    let maxWidth = 0,
      maxHeight = 0;
    const balls = []; //小球实例
    function resize() {
      maxWidth = canvas.width = document.documentElement.clientWidth;
      maxHeight = canvas.height = document.documentElement.clientHeight;
      for (let j = 0; j < balls.length; j++) {
        balls[j].updateSize(maxWidth, maxHeight);
      }
    }

    onMounted(() => {
      // 添加 resize 绑定事件
      window.onresize = resize;
      nextTick(() => {
        canvas = document.getElementById("canvas"); //canvas画布
        const ctx = canvas.getContext("2d");
        maxWidth = canvas.width = document.documentElement.clientWidth;
        maxHeight = canvas.height = document.documentElement.clientHeight;

        for (let i = 0; i < 20; i++) {
          const newBall = new Ball(maxWidth, maxHeight, ctx);
          newBall.draw();
          balls.push(newBall);
        }

        setInterval(() => {
          // 每次画之前都要清除画布
          ctx.clearRect(0, 0, maxWidth, maxHeight);
          for (let j = 0; j < balls.length; j++) {
            balls[j].move();
            balls[j].draw();
          }
        }, 10);
      });
    });

    onUnmounted(() => {
      // 销毁
      window.onresize = null;
      canvas.width = 0;
      canvas.height = 0;
      canvas = null;
    });

    const isRemember = ref(false); //记住密码
    const visiblePass = ref(false); //密码是否可见

    const rememberInfo = localStorage.getItem("rememberInfo");
    if (rememberInfo !== null) {
      const jsonInfo = JSON.parse(rememberInfo);
      state.elForm.user = jsonInfo.user;
      state.elForm.password = CryptoJS.AES.decrypt(
        jsonInfo.password,
        "123456"
      ).toString(CryptoJS.enc.Utf8);
      isRemember.value = true;
    }

    const userExist = ref(false);
    async function checkExist() {
      if (state.elForm.user.trim() !== "") {
        const res = await user.checkExist(state.elForm.user);
        if (res.resultStatus) {
          userExist.value = true;
        } else {
          userExist.value = false;
        }
        console.log(res);
      }
    }

    let publicKey = "";
    /* 获取公钥 */
    async function getPublicKey() {
      const res = await user.getPublicKey();
      if (res.resultStatus) {
        publicKey = res.resultData;
      }
    }
    getPublicKey();

    //设置cookie
    function setCookie(name, value) {
      document.cookie = name + "=" + escape(value); //转码并赋值
    }

    const elFormRef = ref(null);
    let timer = null; //防抖定时器
    /* 登录 */
    function handleLogin() {
      clearTimeout(timer);
      timer = setTimeout(() => {
        elFormRef.value.validate(async (valid) => {
          if (valid) {
            // 定义公钥（换成你自己的公钥）
            const encryptor = new JSEncrypt();
            if (!publicKey) return;
            encryptor.setPublicKey(publicKey); // 设置公钥
            const encryption = encryptor.encrypt(state.elForm.password); // 对需要加密的数据进行加密
            if (!encryption) return;
            const postData = {
              user: state.elForm.user,
              password: encryption,
            };
            const res = await user.login({
              loginData: postData,
              loginType: "USER_PASS",
            });
            if (res.resultStatus) {
              // 登录成功 -- 保存用户信息
              ElMessage.success(t("638"));
              // openSocketConnect();
              localStorage.setItem("token", res.resultData.token);
              setCookie("token", "hello"); //存储localStorage的同时，也存储一个cookie来监听
              localStorage.setItem(
                "permissions",
                res.resultData.userInfo.rolePermissions
              );
              // 记住密码
              if (isRemember.value) {
                const aesEncrypt = CryptoJS.AES.encrypt(
                  state.elForm.password,
                  "123456"
                ).toString();
                localStorage.setItem(
                  "rememberInfo",
                  JSON.stringify({
                    user: state.elForm.user,
                    password: aesEncrypt,
                  })
                );
              } else {
                localStorage.removeItem("rememberInfo");
              }
              // 保存用户基本信息
              localStorage.setItem(
                "userInfo",
                JSON.stringify(res.resultData.userInfo)
              );
              console.log(res.resultData.userInfo);
              sessionStorage.setItem(
                "startTime",
                new Date().getTime().toString()
              );
              // 同步设置vuex
              store.commit("setUserInfo", res.resultData.userInfo);
              system.getTenant().then((res) => {
                if (res.resultStatus) {
                  // 设置租户
                  localStorage.setItem("tenant", res.resultData);
                }
              });
              localStorage.setItem("menusId", 1);
              router.push("/homeIndex");
            }
          } else {
            return false;
          }
        });
      }, 200);
    }
    // 获取logo
    async function getLogo() {
      const res = await system.getLogoURL();
      if (res.resultStatus) {
        state.logo = res.resultData;
      }
    }
    getLogo();
    return {
      ...toRefs(state),
      isRemember,
      visiblePass,
      elFormRef,
      userExist,
      checkExist,
      handleLogin,
      getLogo,
      appName,
    };
  },
};
</script>

<style scoped lang="less">
@import "./../../styles/component-style/checkbox-radio.less";
.full_box {
  position: relative;
  background: rgba(10, 21, 35, 1);
  overflow: hidden;
}
.center_box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 76.75rem;
  height: 45.5rem;
  font-family: Source Han Sans CN;
  .center_half {
    width: 50%;
    height: 100%;
  }
  .center_left {
    background: #0f1a39;
  }
  .center_right {
    position: relative;
    background: #fff;
    .version_name {
      position: absolute;
      bottom: 0.625rem;
      right: 0.625rem;
      color: #ccc;
      font-size: 0.5rem;
    }
  }
}
.center_left {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  .earth {
    width: 64%;
  }
  .hide_name {
    position: absolute;
    top: 3.125rem;
    right: 3.125rem;
    color: rgba(198, 198, 198, 0.01);
    font-size: 5.375rem;
  }
  .logo_div {
    position: absolute;
    left: 2rem;
    bottom: 1.625rem;
    font-size: 1.5rem;
    color: #fefeff;
    font-weight: bold;
    > img,
    > span {
      vertical-align: middle;
    }
    > img {
      margin-right: 1rem;
      width: 1.375rem;
    }
  }
}
.form_box {
  padding-top: 8.125rem;
  padding-left: 3.25rem;
  color: #666666;
  font-size: 1.125rem;
  > h4 {
    font-size: 1.5rem;
    color: #0e173c;
    font-weight: 800;
    line-height: 1;
  }
  > h5 {
    margin: 1.5rem 0 3.125rem;
    .color_word {
      padding: 0 0.5rem;
    }
  }
  .el_form {
    .form_item {
      width: 21.375rem;
      margin-bottom: 1.25rem;
      /deep/ .el-form-item__label {
        color: #666666;
        font-size: 1.125rem;
        margin-bottom: 0.5rem;
      }
      /deep/ .el-form-item__label:before {
        display: none;
      }
      /deep/ .el-form-item__content {
        .el-input {
          height: 2.875rem;
          .el-input__wrapper {
            background: #f8f9fd;
            .el-input__inner {
              color: #333333;
            }
          }
        }
      }
      .iconfont {
        position: absolute;
        top: 0;
        right: 1rem;
        font-size: 1.375rem;
        height: 100%;
        line-height: 2.2;
      }
      .icon-chenggong {
        color: #20d17e;
      }
    }
  }
  .blue_btn {
    height: 2.875rem;
    padding: 0.875rem 4.25rem;
    margin: 2.5rem 0 4.125rem;
    background: #426fe2;
    color: #fff;
  }
}
.theme_word {
  color: #066bad;
}
canvas {
  display: block;
  margin: 0 auto;
}
</style>
