import { t } from '../languages';
function getRandomNum(minNum, maxNum) {
  switch (arguments.length) {
    case 1:
      return Math.round(Math.random() * minNum + minNum);
    case 2:
      return Math.round(Math.random() * (maxNum - minNum) + minNum);
    case 0:
      return 0;
  }
}

//设置随机颜色
function getRandomColor() {
  var allType = '0,1,2,3,4,5,6,7,8,9,a,b,c,d,e,f'; //16进制颜色
  var allTypeArr = allType.split(','); //通过','分割为数组
  var color = '#';
  for (var i = 0; i < 6; i++) {
    var random = parseInt(Math.random() * allTypeArr.length);
    //随机生成一个0-16的数
    color += allTypeArr[random];
  }
  return color; //返回随机生成的颜色
}

// 创建一个Ball的构造函数
function Ball(maxWidth, maxHeight, ctx) {
  this.ctx = ctx;
  this.maxWidth = maxWidth;
  this.maxHeight = maxHeight;
  // 随机半径
  this.r = getRandomNum(1, 10);
  // 随机x,y坐标
  this.x = getRandomNum(this.r, this.maxWidth - this.r);
  this.y = getRandomNum(this.r, this.maxHeight - this.r);
  // 平移速度,正负区间是为了移动方向多样
  // this.speedX = getRandomNum(-1, 1);
  // this.speedY = getRandomNum(-1, 1);
  this.speedX = Math.random() * 2 - 1;
  this.speedY = Math.random() * 2 - 1;
  // 颜色随机
  this.color = getRandomColor();
}

Ball.prototype.move = function () {
  // 判断边界值,让圆球始终保证在画面内
  if (this.x > this.maxWidth - this.r || this.x < this.r) {
    this.speedX = -this.speedX;
  }
  if (this.y > this.maxHeight - this.r || this.y < this.r) {
    this.speedY = -this.speedY;
  }
  this.x += this.speedX;
  this.y += this.speedY;
};

Ball.prototype.draw = function () {
  this.ctx.beginPath();
  this.ctx.arc(this.x, this.y, this.r, 0, Math.PI * 2, false);
  this.ctx.fillStyle = this.color;
  this.ctx.fill();
  this.ctx.closePath();
};

Ball.prototype.updateSize = function (width, height) {
  this.maxWidth = width;
  this.maxHeight = height;
};


export {
  Ball
}